<div class="page-title-area ">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Utility</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Utility</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="utility-area pt-100">
    <div class="container">

        <div class="row justify-content-center">
            <div class="col-12 col-md-4 mb-3 text-center">
                <a routerLink="/sitting-roster" class="contact-btn btn-primary w-100 py-3">Sitting Roster</a>
            </div>
            <div class="col-12 col-md-4 mb-3 text-center">
                <a routerLink="/incomplete-list" class="contact-btn btn-primary w-100 py-3">Incomplete List</a>
            </div>
            <div class="col-12 col-md-4 mb-3 text-center">
                <a routerLink="/contact" class="contact-btn btn-primary w-100 py-3">Feedback</a>
            </div>
        </div>

       
    </div>

<div style="height: 450px; margin-top: 30px;">
    <div class="col-12 col-md-4 mb-3 text-center" style="margin: 0 auto;">
        <a href="https://wa.me/9779004600" class="contact-btn btn-primary w-100 py-3">
            <i class="icofont-whatsapp"></i> WhatsApp Me
        </a>
    </div>
</div>
</section>

