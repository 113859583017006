<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Case Studies</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Case Studies</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="portfolio-area portfolio-area-two pt-100">
    <div class="container">
        <div class="section-title">
            <h2>Check Out Our Popular Case Studies</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="assets/img/home-one/portfolio/1.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Crime</span>
                        <h3><a routerLink="/case-study-details">Critical Murder Case</a></h3>
                        <p>10 Feb 2021</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow fadeInUp" data-wow-delay=".4s">
                    <img src="assets/img/home-one/portfolio/2.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Rights</span>
                        <h3><a routerLink="/case-study-details">Property Problem</a></h3>
                        <p>11 Feb 2021</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow fadeInUp" data-wow-delay=".5s">
                    <img src="assets/img/home-one/portfolio/3.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Finance</span>
                        <h3><a routerLink="/case-study-details">Money Loundering</a></h3>
                        <p>12 Feb 2021</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="assets/img/home-one/portfolio/4.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Cyber</span>
                        <h3><a routerLink="/case-study-details">Cyber Crime Case</a></h3>
                        <p>13 Feb 2021</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow fadeInUp" data-wow-delay=".4s">
                    <img src="assets/img/home-one/portfolio/5.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Hack</span>
                        <h3><a routerLink="/case-study-details">Bank Hack Case</a></h3>
                        <p>14 Feb 2021</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow fadeInUp" data-wow-delay=".5s">
                    <img src="assets/img/home-one/portfolio/6.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Politics</span>
                        <h3><a routerLink="/case-study-details">Political Case</a></h3>
                        <p>15 Feb 2021</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow fadeInUp" data-wow-delay=".3s">
                    <img src="assets/img/home-one/portfolio/7.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Social</span>
                        <h3><a routerLink="/case-study-details">Social Case</a></h3>
                        <p>16 Feb 2021</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="portfolio-item wow fadeInUp" data-wow-delay=".4s">
                    <img src="assets/img/home-one/portfolio/8.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Children</span>
                        <h3><a routerLink="/case-study-details">Children Rights</a></h3>
                        <p>17 Feb 2021</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="portfolio-item wow fadeInUp" data-wow-delay=".5s">
                    <img src="assets/img/home-one/portfolio/9.jpg" alt="Portfolio">

                    <div class="portfolio-inner">
                        <span>Women</span>
                        <h3><a routerLink="/case-study-details">Women Rights</a></h3>
                        <p>18 Feb 2021</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="case-pagination">
            <ul>
                <li><a routerLink="/case-study"><i class="icofont-simple-left"></i></a></li>
                <li><a routerLink="/case-study">1</a></li>
                <li><a routerLink="/case-study">2</a></li>
                <li><a routerLink="/case-study">3</a></li>
                <li><a routerLink="/case-study"><i class="icofont-simple-right"></i></a></li>
            </ul>
        </div>
    </div>
</section>