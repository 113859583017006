import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-incomplete-list',
  templateUrl: './incomplete-list.component.html',
  styleUrls: ['./incomplete-list.component.scss']
})
export class IncompleteListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
