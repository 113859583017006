<div class="page-title-area title-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Practice Details</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Practice Details</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="practice-details-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="practice-details-item">
                    <div class="practice-details-content">
                        <div class="section-title text-start">
                            <h2>Criminal Law</h2>
                        </div>
                        <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                        <p>Risus commodo viverra maecenas accumsan lacus vel facilisis. . Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                        <ul>
                            <li><span>Retribution –</span> Criminals ought to Be Punished in some way.</li>
                            <li><span>Deterrence –</span> Individual deterrence is aimed toward the specific offender.</li>
                            <li><span>Incapacitation – </span> Designed simply to keep criminals away from society.</li>
                            <li><span>Rehabilitation –</span> Aims at transforming an offender into a valuable member of society.</li>
                        </ul>
                        <p>Risus commodo viverra maecenas accumsan lacus vel facilisis. . Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                    </div>
                </div>

                <div class="practice-details-case">
                    <div class="row">
                        <div class="col-sm-6 col-lg-6">
                            <div class="portfolio-item  wow fadeInUp" data-wow-delay=".3s">
                                <img src="assets/img/home-one/portfolio/1.jpg" alt="Portfolio">

                                <div class="portfolio-inner">
                                    <span>Crime</span>
                                    <h3><a routerLink="/case-study-details">Critical Murder Case</a></h3>
                                    <p>15 Feb 2021</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="portfolio-item wow fadeInUp" data-wow-delay=".4s">
                                <img src="assets/img/home-one/portfolio/2.jpg" alt="Portfolio">

                                <div class="portfolio-inner">
                                    <span>Rights</span>
                                    <h3><a routerLink="/case-study-details">Property Problem</a></h3>
                                    <p>16 Feb 2021</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="portfolio-item wow fadeInUp" data-wow-delay=".3s">
                                <img src="assets/img/home-one/portfolio/3.jpg" alt="Portfolio">

                                <div class="portfolio-inner">
                                    <span>Finance</span>
                                    <h3><a routerLink="/case-study-details">Money Loundering</a></h3>
                                    <p>17 Feb 2021</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="portfolio-item wow fadeInUp" data-wow-delay=".4s">
                                <img src="assets/img/home-one/portfolio/1.jpg" alt="Portfolio">

                                <div class="portfolio-inner">
                                    <span>Crime</span>
                                    <h3><a routerLink="/case-study-details">Cyber Crime Case</a></h3>
                                    <p>18 Feb 2021</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="practice-details-item">
                    <div class="blog-details-category">
                        <ul>
                            <li><a routerLink="/practice-details">Criminal Law <i class="icofont-arrow-right"></i></a></li>
                            <li><a routerLink="/practice-details">Civil Law <i class="icofont-arrow-right"></i></a></li>
                            <li><a routerLink="/practice-details">Education Law <i class="icofont-arrow-right"></i></a></li>
                            <li><a routerLink="/practice-details">Family Law <i class="icofont-arrow-right"></i></a></li>
                            <li><a routerLink="/practice-details">Cyber Law <i class="icofont-arrow-right"></i></a></li>
                        </ul>
                    </div>

                    <div class="practice-details-contact">
                        <h3>Contact Info</h3>

                        <ul>
                            <li><i class="flaticon-call"></i> <a href="tel:+07554332322">Call : +07 554 332 322</a></li>
                            <li><i class="flaticon-email"></i> <a href="mailto:hello@lufz.com">hello@lufz.com</a></li>
                            <li><i class="flaticon-pin"></i> 4th Floor, 408 No Chamber</li>
                        </ul>
                    </div>

                    <div class="practice-details-brochures">
                        <h3>Brochures</h3>
                        <p>Risus is commodo viverra maecenas accumsan lacus vel facilisis. . Quis is a ipsum suspendisse ultrices gravida</p>
                        <a class="cmn-btn" href="#"><i class="flaticon-download"></i>Download</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="help-shape">
        <img src="assets/img/home-one/6.png" alt="Shape">
    </div>
</div>